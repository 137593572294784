export const SnapLogo = ({ color, size }: { color: string; size: number }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 125 125`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M93.000000,223.000000 
	C62.019169,223.000000 31.538338,223.000000 1.028753,223.000000 
	C1.028753,149.065689 1.028753,75.131355 1.028753,1.098513 
	C59.563976,1.098513 118.128174,1.098513 176.846191,1.098513 
	C176.846191,74.999741 176.846191,148.999847 176.846191,223.000000 
	C149.131653,223.000000 121.315826,223.000000 93.000000,223.000000 
M90.079346,67.016899 
	C86.913322,67.011017 83.732445,67.179596 80.588707,66.911957 
	C79.359886,66.807343 77.805054,66.013382 77.107376,65.030403 
	C74.959496,62.004242 72.976631,58.828552 71.245880,55.544319 
	C63.643127,41.117493 56.195892,26.608526 48.546333,12.206857 
	C47.839947,10.876956 46.102486,10.094715 44.843792,9.058177 
	C44.229210,10.592435 43.093716,12.122245 43.080578,13.661637 
	C42.948280,29.157648 43.113194,44.657024 42.867584,60.150276 
	C42.834026,62.267357 41.476952,64.815941 39.935059,66.376869 
	C33.855965,72.530991 26.639610,77.726166 21.343090,84.455994 
	C8.764312,100.438744 3.323282,119.221375 5.214818,139.344910 
	C7.145494,159.884842 15.863889,177.664398 31.308111,191.825684 
	C47.642200,206.802917 66.861664,214.109665 89.882790,213.979065 
	C95.387711,213.316589 101.046059,213.254410 106.372375,211.893112 
	C138.265152,203.741928 159.492584,184.297653 168.378754,152.180435 
	C172.457275,137.439377 171.685165,122.865936 167.125702,108.291702 
	C162.225540,92.628441 152.724167,80.249435 140.950119,69.226097 
	C139.301987,67.683044 138.157303,64.764824 138.126495,62.465122 
	C137.894531,45.148266 138.049271,27.826506 137.912354,10.507627 
	C137.900574,9.017178 136.654846,7.536484 135.982315,6.051259 
	C134.842270,7.007463 133.293488,7.739482 132.626663,8.955241 
	C127.163857,18.915081 121.883430,28.974749 116.510460,38.984150 
	C112.975777,45.568996 108.625015,51.836346 106.013252,58.763725 
	C103.133148,66.402809 98.028946,68.002373 90.079346,67.016899 
z"
    />
    <path
      fill="#000DBD"
      opacity="1.000000"
      stroke="none"
      d="
M88.994484,213.833862 
	C66.861664,214.109665 47.642200,206.802917 31.308111,191.825684 
	C15.863889,177.664398 7.145494,159.884842 5.214818,139.344910 
	C3.323282,119.221375 8.764312,100.438744 21.343090,84.455994 
	C26.639610,77.726166 33.855965,72.530991 39.935059,66.376869 
	C41.476952,64.815941 42.834026,62.267357 42.867584,60.150276 
	C43.113194,44.657024 42.948280,29.157648 43.080578,13.661637 
	C43.093716,12.122245 44.229210,10.592435 44.843792,9.058176 
	C46.102486,10.094715 47.839947,10.876956 48.546333,12.206857 
	C56.195892,26.608526 63.643127,41.117493 71.245880,55.544319 
	C72.976631,58.828552 74.959496,62.004242 77.107376,65.030403 
	C77.805054,66.013382 79.359886,66.807343 80.588707,66.911957 
	C83.732445,67.179596 86.913322,67.011017 90.483261,67.474625 
	C90.258125,70.006401 89.088646,72.078445 89.080971,74.154785 
	C88.963142,106.047485 88.996620,137.940750 88.537399,169.908447 
	C85.592545,169.988663 83.060966,170.298187 80.626022,169.943817 
	C72.883041,168.816879 64.564034,169.029419 59.187943,161.689804 
	C58.202366,160.344254 57.156017,159.043198 56.137714,157.721619 
	C55.648323,157.862885 55.158932,158.004150 54.669540,158.145416 
	C54.763798,159.998398 54.664387,161.886078 54.988846,163.697845 
	C55.939156,169.004379 58.645912,172.010818 63.826954,174.991837 
	C71.916885,179.646530 79.771317,182.637619 88.996780,182.624359 
	C88.997269,193.333023 88.995880,203.583450 88.994484,213.833862 
M66.685600,106.167358 
	C65.036591,103.585815 63.445374,99.782295 59.907284,101.254486 
	C57.431290,102.284752 54.517582,105.597870 54.391594,108.025925 
	C54.259182,110.577774 56.627861,114.240143 58.971764,115.687813 
	C61.807076,117.439011 64.555397,114.883766 65.920502,112.037628 
	C66.651390,110.513779 66.642570,108.635139 66.685600,106.167358 
z"
    />
    <path
      fill="#7E0081"
      opacity="1.000000"
      stroke="none"
      d="
M88.994919,169.833878 
	C88.996620,137.940750 88.963142,106.047485 89.080971,74.154785 
	C89.088646,72.078445 90.258125,70.006401 90.940704,67.549240 
	C98.028946,68.002373 103.133148,66.402809 106.013252,58.763725 
	C108.625015,51.836346 112.975777,45.568996 116.510460,38.984150 
	C121.883430,28.974749 127.163857,18.915081 132.626663,8.955241 
	C133.293488,7.739482 134.842270,7.007463 135.982315,6.051259 
	C136.654846,7.536484 137.900574,9.017178 137.912354,10.507627 
	C138.049271,27.826506 137.894531,45.148266 138.126495,62.465122 
	C138.157303,64.764824 139.301987,67.683044 140.950119,69.226097 
	C152.724167,80.249435 162.225540,92.628441 167.125702,108.291702 
	C171.685165,122.865936 172.457275,137.439377 168.378754,152.180435 
	C159.492584,184.297653 138.265152,203.741928 106.372375,211.893112 
	C101.046059,213.254410 95.387711,213.316589 89.438637,213.906464 
	C88.995880,203.583450 88.997269,193.333023 89.454704,182.551697 
	C105.380753,182.854385 117.494232,177.059769 125.704079,163.738739 
	C126.491440,162.461197 126.641937,160.791122 127.088097,159.303284 
	C126.615616,159.010056 126.143135,158.716843 125.670654,158.423615 
	C124.326874,159.564713 123.060410,160.816269 121.622452,161.822800 
	C119.009476,163.651855 116.438286,165.628265 113.594627,167.023315 
	C105.773605,170.860168 97.339401,170.126343 88.994919,169.833878 
M122.673172,110.830338 
	C122.710503,109.375664 123.082176,107.831505 122.721359,106.483391 
	C121.709961,102.704536 117.027504,99.482681 114.783813,101.306236 
	C112.620026,103.064850 110.300896,106.198814 110.355293,108.677971 
	C110.409294,111.138870 112.824539,114.582832 115.127296,115.710152 
	C118.354378,117.289948 120.804436,114.572105 122.673172,110.830338 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M88.537399,169.908447 
	C97.339401,170.126343 105.773605,170.860168 113.594627,167.023315 
	C116.438286,165.628265 119.009476,163.651855 121.622452,161.822800 
	C123.060410,160.816269 124.326874,159.564713 125.670654,158.423615 
	C126.143135,158.716843 126.615616,159.010056 127.088097,159.303284 
	C126.641937,160.791122 126.491440,162.461197 125.704079,163.738739 
	C117.494232,177.059769 105.380753,182.854385 89.452820,182.093445 
	C79.771317,182.637619 71.916885,179.646530 63.826954,174.991837 
	C58.645912,172.010818 55.939156,169.004379 54.988846,163.697845 
	C54.664387,161.886078 54.763798,159.998398 54.669540,158.145416 
	C55.158932,158.004150 55.648323,157.862885 56.137714,157.721619 
	C57.156017,159.043198 58.202366,160.344254 59.187943,161.689804 
	C64.564034,169.029419 72.883041,168.816879 80.626022,169.943817 
	C83.060966,170.298187 85.592545,169.988663 88.537399,169.908447 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M66.826584,106.541985 
	C66.642570,108.635139 66.651390,110.513779 65.920502,112.037628 
	C64.555397,114.883766 61.807076,117.439011 58.971764,115.687813 
	C56.627861,114.240143 54.259182,110.577774 54.391594,108.025925 
	C54.517582,105.597870 57.431290,102.284752 59.907284,101.254486 
	C63.445374,99.782295 65.036591,103.585815 66.826584,106.541985 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M122.400497,111.119949 
	C120.804436,114.572105 118.354378,117.289948 115.127296,115.710152 
	C112.824539,114.582832 110.409294,111.138870 110.355293,108.677971 
	C110.300896,106.198814 112.620026,103.064850 114.783813,101.306236 
	C117.027504,99.482681 121.709961,102.704536 122.721359,106.483391 
	C123.082176,107.831505 122.710503,109.375664 122.400497,111.119949 
z"
    />
  </svg>
);
